export default {
  name: "TaskComplete",
  props: {
    id: String,
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.push("/task/list");
    },
  },
};
